
export default {
  name: 'PhotoTicker',
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
        },
        speed: 7000,
        loop: true,
        slidesPerView: '2',
        spaceBetween: 6,
        freeMode: true,
        grabCursor: true,
        breakpoints: {
          1024: {
            slidesPerView: '3',
            spaceBetween: 20,
          },
        },
      },
    }
  },
}
