
// import Christmas from "~/components/version-2023/Christmas.vue";
import Banner from '~/components/version-2023/Banner'
// import PrizePopup from '~/components/version-2023/PrizePopup'
import AboutProduct from '~/components/version-2023/AboutProduct'
// import AboutUs from '~/components/version-2023/AboutUs'
import PhotoTicker from '~/components/version-2023/PhotoTicker'
// import InWorld from '~/components/version-2023/InWorld'
// import OurFlagmans from '~/components/version-2023/OurFlagmans'
import ProfessionalTools from '~/components/version-2023/ProfessionalTools'
import VideoBg from '~/components/version-2023/VideoBg'
import Advantages from '~/components/version-2023/Advantages'
// import FirstPurchase from '~/components/version-2023/FirstPurchase'
import OurPrinciples from '~/components/version-2023/OurPrinciples'
import TestDriveModal from '~/components/version-2023/TestDriveModal'
import ContentModal from '~/components/helpers/ContentModal'
import GdprPopup from '~/components/version-2023/GdprPopup'
import GoogleMap from '~/components/version-2023/GoogleMap'
// import OpeningShops from '~/components/version-2023/OpeningShops'
// import PrizeBanner from '~/components/version-2023/PrizeBanner'
import TradeIn from '~/components/version-2023/TradeIn.vue'
export default {
  components: {
    Banner,
    // PrizePopup,
    AboutProduct,
    // AboutUs,
    PhotoTicker,
    // Christmas,
    // InWorld,
    // OurFlagmans,
    ProfessionalTools,
    VideoBg,
    Advantages,
    OurPrinciples,
    TestDriveModal,
    ContentModal,
    GdprPopup,
    GoogleMap,
    // FirstPurchase,
    // OpeningShops,
    // PrizeBanner,
    TradeIn,
  },
  head() {
    return {
      title: this.$t('head.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('head.description'),
        },
      ],
    }
  },
  mounted() {
    window.addEventListener('load', this.onWindowLoad)
    window.addEventListener('hashchange', this.onWindowLoad)
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onWindowLoad)
    window.removeEventListener('hashchange', this.onWindowLoad)
  },
  methods: {
    onWindowLoad() {
      if (window.location.hash) {
        if (window.location.hash === '#subscribe') {
          window.eS('showForm', { formVariantId: 'f8632v8632' })
        } else {
          const header = document.querySelector('.header')
          console.log(header.offsetHeight)
          setTimeout(() => {
            this.$gsap.to(window, {
              duration: 1,
              scrollTo: {
                y: window.location.hash,
                offsetY: header.offsetHeight,
              },
            })
          }, 500)
        }
      }
    },
  },
}
