
export default {
  name: 'Banner',
  methods: {
    scrollToForm() {
      const header = document.querySelector('.header')
      console.log(header)
      if (header) {
        this.$gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: '.footer',
            offsetY: header.offsetHeight + 50,
          },
        })
      }
    },
  },
}
