
export default {
  name: 'TestDriveModal',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    hide() {
      // console.log(this)
      this.show = false
    },
  },
}
