
export default {
  name: 'TradeIn',
  props: {
    isTradeIn: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    linkChunk() {
      if (this.isTradeIn) {
        return '/images/trade-in/'
      }
      return '/images/sale/sale-15-'
    },
  },
}
