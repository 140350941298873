
export default {
  name: 'GdprPopup',
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    hide() {
      this.$cookies.set('gdpr', 'accepted')
      this.isVisible = false
    },
  },
  mounted() {
    const cookieRes = this.$cookies.get('gdpr')
    if (!cookieRes) {
      this.isVisible = true
    }
  },
}
