
export default {
  name: 'ProfessionalTools',
  methods: {
    scrollToBlock(selector) {
      const header = document.querySelector('.header')
      if (header) {
        this.$gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: selector,
            offsetY: header.offsetHeight,
          },
        })
        this.open = false
      }
    },
  },
}
