
export default {
  name: 'ContentModal',
  data() {
    return {
      type: '',
      videoId: '',
      iframe360Src: '',
    }
  },
  methods: {
    beforeOpen(event) {
      this.type = event.params && event.params.type ? event.params.type : ''
      this.videoId =
        event.params && event.params.videoId ? event.params.videoId : ''
      this.iframe360Src =
        event.params && event.params.iframe360Src
          ? event.params.iframe360Src
          : ''
    },
  },
}
