
export default {
  name: 'MainVideo',
  props: {
    videoId: {
      type: String,
      default: '',
      required: true,
    },
    plainButton: Boolean,
    isModal: Boolean,
    previewQuality: {
      type: String,
      default: 'sddefault',
    },
  },
  data() {
    return {
      playVideo: false,
    }
  },
  computed: {
    hasOverlayContentSlot() {
      return !!this.$scopedSlots['overlay-content']
    },
  },
  methods: {
    toggleVideoPlaying(bool) {
      this.playVideo = bool
    },
    isVisible(isVisible) {
      if (!isVisible) this.toggleVideoPlaying(isVisible)
    },
  },
}
